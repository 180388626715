<template>
  <div>
    <!-- ANCHOR Filters -->
    <InvoiceListFilters
      ref="invoiceListFilters"
      :search-keywords.sync="searchKeywords"
      :fixed-invoice-status="fixedInvoiceStatus"
      :fixed-invoice-types="fixedInvoiceTypes"
      @refetch-data-with-filters="refetchData"
      @refetch-data-without-filters="clearFilter"
    />

    <!-- Table Container Card -->
    <b-card
      style="max-height: 70vh"
      class=""
      no-body
    >
      <!-- SECTION Table Top -->
      <div>
        <b-row class="m-1">
          <!-- ANCHOR Table Top - Per Page -->
          <b-col
            cols="4"
            md="auto"
            class="px-0 mr-md-1"
          >
            <v-select
              v-model="sizePerPage"
              style="font-size: 1rem;"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sizePerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>
            </v-select>
          </b-col>

          <b-col
            v-if="enabledActions"
            cols="8"
            md="auto"
            class="d-flex justify-content-end px-50"
            order-md="3"
          >
            <!-- ANCHOR Table Top - Buttons(Add, Export) -->
            <div class="d-none d-lg-flex">
              <!-- Button Add -->
              <b-button
                style="min-width: 120px"
                variant="info"
                class="mr-1 px-lg-1 px-md-75"
                :to="{ name: 'apps-invoicesOld-add' }"
              >
                <span class="text-nowrap">
                  <feather-icon
                    class="cursor-pointer"
                    icon="PlusSquareIcon"
                    size="16"
                  />
                  {{ $t('invoice.title') }}
                </span>
              </b-button>

              <!-- Button Export -->
              <b-button
                style="min-width: 120px"
                variant="info"
                class="px-lg-1 px-md-75"
                @click="confirmExport()"
              >
                <span class="text-nowrap">
                  <feather-icon
                    class="cursor-pointer"
                    icon="DownloadIcon"
                    size="16"
                  />
                  {{ $t('export') }}
                </span>
              </b-button>
            </div>

            <!-- ANCHOR Table Top - Dropdown -->
            <div
              v-if="enabledActions"
              class="d-block d-lg-none"
            >
              <b-dropdown
                variant="info"
                class="m-lg-2"
                boundary="window"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="MenuIcon"
                    size="16"
                  />
                  <span class="pl-50">{{ $t('moreDropdownTitle') }}</span>
                </template>
                <b-dropdown-item
                  :to="{ name: 'apps-invoicesOld-add' }"
                >
                  {{ $t('invoice.moreDropdown.add') }}
                </b-dropdown-item>
                <b-dropdown-item
                  @click="confirmExport()"
                >
                  {{ $t('invoice.moreDropdown.export') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>

          <!-- ANCHOR Table Top - Search -->
          <b-col
            cols="12"
            md="auto"
            class="mt-1 mt-md-0 px-50 flex-grow-1"
          >
            <div class="w-100 d-flex align-items-center">
              <b-input-group
                size="md"
                class="w-100"
                :class="{'mr-md-1': enabledActions}"
              >
                <template #prepend>
                  <div class="d-flex align-items-center border rounded-left px-1 bg-light-info">
                    <feather-icon
                      icon="SearchIcon"
                      size="20"
                    />
                  </div>
                </template>

                <b-form-input
                  v-model="searchKeywords"
                  type="search"
                  :placeholder="$t('invoice.phSearch')"
                  :reduce="val => val.value"
                  debounce="500"
                  trim
                  @input="onDebounceSearch($event, onRefetchData)"
                />
              </b-input-group>
            </div>
          </b-col>
        </b-row>

        <b-row
          style="background-color: #92CBFD;"
          class="font-weight-bold text-white mx-0 py-25 py-md-75"
        >
          <b-col
            md="auto"
            class="d-flex align-items-center px-0 mx-1 mx-md-2"
          >
            <!-- ANCHOR Table Top - Selected Invoices -->
            <div class="d-inline-block">
              <template v-if="notSelect">
                {{ $tc('invoice.selectionCount', 0) }}
              </template>
              <template v-else-if="selectionCount === 1">
                {{ $tc('invoice.selectionCount', 1) }}
              </template>
              <template v-else>
                {{ $tc('invoice.selectionCount', selectionCount, { count: selectionCount }) }}
              </template>
            </div>
          </b-col>

          <b-col
            v-if="enabledActions"
            md="auto"
            class="d-flex flex-grow-1 justify-content-end align-items-center px-0 mx-1 mx-md-2"
          >
            <!-- ANCHOR Table Top - Action: Send To Approve -->
            <span
              v-if="!roleMama"
              :class="notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer'"
              @click="onHandleSendToApproveMultipleInvoices(selectedInvoices)"
            >
              {{ $t('invoice.btn.sendToApprove') }}
            </span>

            <template v-if="roleMama">
              <template v-if="!isFixedInvoiceStatusDraftOrApproved">
                <!-- ANCHOR Table Top - Action: Approve -->
                <span
                  class="ml-50 ml-md-1"
                  :class="notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer'"
                  @click="onHandleApproveMultipleInvoices(selectedInvoices)"
                >
                  {{ $t('invoice.btn.approve') }}
                </span>

                <!-- ANCHOR Table Top - Action: Disapprove -->
                <span
                  class="ml-50 ml-md-1"
                  :class="notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer'"
                  @click="onHandleApproveMultipleInvoices(selectedInvoices, false)"
                >
                  {{ $t('invoice.btn.disapprove') }}
                </span>
              </template>

              <template v-if="!isFixedInvoiceStatusAwaitingApproval">
                <!-- ANCHOR Table Top - Action: Sign -->
                <span
                  class="ml-1 ml-md-2"
                  :class="notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer'"
                  @click="onHandleSignMultipleInvoices(selectedInvoices)"
                >
                  {{ $t('invoice.btn.sign') }}
                </span>

                <!-- ANCHOR Table Top - Action: Refuse To Sign -->
                <span
                  class="ml-50 ml-md-1"
                  :class="notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer'"
                  @click="onHandleRefuseSignMultipleInvoices(selectedInvoices)"
                >
                  {{ $t('invoice.btn.refuseSign') }}
                </span>
              </template>
            </template>

            <!-- ANCHOR Table Top - Action: Send Email -->
            <span
              class="ml-1 ml-md-2"
              :class="notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer'"
              @click="onHandleSendEmailMultipleInvoices(selectedInvoices)"
            >
              {{ $t('invoice.btn.sendEmail') }}
            </span>
          </b-col>
        </b-row>
      </div>
      <!-- !SECTION -->

      <!-- SECTION Table content -->
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <b-table
          ref="refInvoiceListTable"
          style="max-height: 45vh"
          sticky-header
          responsive
          show-empty
          bordered
          primary-key="id"
          class="position-relative"
          :items="fetchInvoices"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :empty-text="$t('noRecordFund')"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- ANCHOR Table Header -->
          <template
            v-for="column in tableColumns"
            v-slot:[`head(${column.key})`]="data"
          >
            <div
              :key="column.label"
              class="text-dark"
            >
              <div
                v-if="data.label === 'checkbox'"
                @click.prevent.stop="onHandleSelectAllOrSelectNone()"
              >
                <b-form-checkbox
                  v-show="!showCheckboxSelectAll"
                  :indeterminate="true"
                  class="mr-1 pl-0"
                  inline
                />
                <b-form-checkbox
                  v-show="showCheckboxSelectAll"
                  class="mr-1 pl-0"
                  inline
                  :checked="isSelectAll"
                  :disabled="isAlreadySelectAll"
                />
              </div>
              <div
                v-else-if="data.label === 'emailStatus'"
                :style="$i18n.locale === 'vi' ? 'width: 82px;' : 'width: 65px;'"
              >
                {{ $t('invoice.columns.emailStatus') }}
              </div>
              <span
                v-else
                class="text-nowrap"
              >
                {{ $t(`invoice.columns.${data.label}`) }}
              </span>
            </div>
          </template>

          <!-- ANCHOR Column Checkbox -->
          <template #cell(checkbox)="{ item }">
            <b-form-checkbox
              class="mr-1 mt-50 pl-0"
              name="check-box"
              inline
              :disabled="isAlreadySelected(item)"
              :checked="isAlreadySelected(item) || isChecked(item.id)"
              @change="toggleSelectItem(item)"
            />
          </template>

          <!-- ANCHOR Column invoiceNumber -->
          <template #cell(invoiceNumber)="{ item }">
            <b-link
              v-if="actions.canViewDetail(item.invoiceType, item.status)"
              class="font-weight-bold d-block text-nowrap text-info pb-0"
              :to="{ name: 'apps-invoicesOld-view', params: { id: item.id } }"
            >
              {{ item.invoiceNumber }}
            </b-link>
            <div
              v-else
              class="text-nowrap pb-0"
            >
              {{ item.invoiceNumber }}
            </div>
          </template>

          <!-- ANCHOR Column issueDate(publicDate) -->
          <template #cell(publicDate)="{ item }">
            <div class="font-weight-bold text-nowrap">
              {{ getDate(item.publicDate) }}
            </div>
          </template>

          <!-- ANCHOR Column customer -->
          <template #cell(customer)="{ item }">
            <div>
              <div class="text-nowrap">
                {{ item.company ? item.company.name : item.companyName }}
              </div>
              <div class="text-nowrap">
                {{ item.customer ? getLocaleFullName(item.customer) : item.customerName }}
              </div>
              <div>
                {{ $t('invoice.taxCode') }}:
                {{ item.taxCode }}
              </div>
              <div>
                {{ item.company ? item.company.address : item.address }}
              </div>
            </div>
          </template>

          <!-- ANCHOR Column total (total payment includes all collection fee) -->
          <template #cell(totalPayment)="{ item: { invoiceItems } }">
            <div class="font-weight-bold text-nowrap">
              {{ formatCurrency(getSumOfTotalPayment(invoiceItems)) }}
            </div>
          </template>

          <!-- ANCHOR Column totalPreTax (totalAmountBeforeTax) -->
          <template #cell(totalAmountBeforeTax)="{ item: { invoiceItems } }">
            <div class="font-weight-bold text-nowrap">
              {{ formatCurrency(getTotalAmountBeforeTax(invoiceItems)) }}
            </div>
          </template>

          <!-- ANCHOR Column tax (totalVat) -->
          <template #cell(totalVat)="{ item: { invoiceItems } }">
            <div class="font-weight-bold text-nowrap">
              {{ formatCurrency(getTotalVat(invoiceItems)) }}
            </div>
          </template>

          <!-- ANCHOR Column collectionFee -->
          <template #cell(collectionFee)="{ item: { invoiceItems } }">
            <div class="font-weight-bold text-nowrap">
              {{ formatCurrency(getTotalCollectionFee(invoiceItems)) }}
            </div>
          </template>

          <!-- ANCHOR Column taCode (codeCQT) -->
          <template #cell(taCode)="{ item: { codeCQT } }">
            <div class="text-nowrap">
              {{ codeCQT }}
            </div>
          </template>

          <!-- ANCHOR Column type (invoiceType) -->
          <template #cell(type)="{ item: { invoiceType } }">
            <b-badge
              variant="info"
              class="badge-glow"
            >
              {{ getLocaleLabelOfInvoiceType(invoiceType) }}
            </b-badge>
          </template>

          <!-- ANCHOR Column status -->
          <template #cell(status)="{ item: { status } }">
            <b-badge
              variant="info"
              class="badge-glow"
            >
              {{ getLocaleLabelOfInvoiceStatus(status) }}
            </b-badge>
          </template>

          <!-- ANCHOR Column emailStatus -->
          <template #cell(emailStatus)="{ item: { emailStatus } }">
            <div class="text-nowrap">
              {{ emailStatus
                ? $t('invoice.emailStatusOptions.sent')
                : $t('invoice.emailStatusOptions.notSent')
              }}
            </div>
          </template>

          <!-- ANCHOR Column errNoti -->
          <template #cell(errNoti)="{ item }">
            <div class="text-nowrap">
              <!-- TODO: map data -->
              {{ item.isErrorNotification ? 'Có thông báo sai sót' : '' }}
            </div>
          </template>

          <!-- ANCHOR Column createdAt -->
          <template #cell(createdAt)="{ item: { createdAt, createdBy } }">
            <div class="text-nowrap">
              {{ dateTime(createdAt) }}
            </div>
            <div class="text-nowrap">
              {{ getLocaleFullName(createdBy) }}
            </div>
          </template>

          <!-- ANCHOR Column updatedAt -->
          <template #cell(updatedAt)="{ item: { updatedAt, updatedBy } }">
            <div class="text-nowrap">
              {{ dateTime(updatedAt) }}
            </div>
            <div class="text-nowrap">
              {{ getLocaleFullName(updatedBy) }}
            </div>
          </template>

          <!-- ANCHOR Column Actions -->
          <template #cell(actions)="{ index, item }">
            <div
              v-if="actions.hasActions(item.invoiceType, item.status)"
              class=" text-right text-nowrap"
            >
              <!-- ANCHOR Table row action: Send By Email -->
              <template v-if="actions.canSendEmail(item.invoiceType, item.status)">
                <feather-icon
                  :id="`invoice-row-${item.id}-send-icon`"
                  icon="SendIcon"
                  class="text-warning cursor-pointer mr-50"
                  size="16"
                  @click="onHandleSendEmailMultipleInvoices([item])"
                />
                <b-tooltip
                  :title="$t('invoice.sendByEmail')"
                  class="cursor-pointer"
                  :target="`invoice-row-${item.id}-send-icon`"
                />
              </template>

              <!-- ANCHOR Table row action: Preview Invoice -->
              <b-link
                v-if="actions.canPreview(item.invoiceType, item.status)"
                class="text-info"
                @click="onPreviewByItem(item)"
              >
                <feather-icon
                  :id="`invoice-row-${item.id}-preview-icon`"
                  icon="EyeIcon"
                  class="cursor-pointer mr-50"
                  size="16"
                />
                <b-tooltip
                  :title="$t('invoice.preview')"
                  :target="`invoice-row-${item.id}-preview-icon`"
                />
              </b-link>

              <!-- ANCHOR Column Actions - Dropdown -->
              <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <!-- ANCHOR Table row action dropdown: Send To Approve Invoice -->
                <b-dropdown-item
                  v-if="!roleMama && actions.canSendToApprove(item.invoiceType, item.status)"
                  @click="onHandleSendToApproveMultipleInvoices([item])"
                >
                  <feather-icon icon="ChevronsRightIcon" />
                  <span class="align-middle ml-50">{{ $t('invoice.btn.sendToApprove') }}</span>
                </b-dropdown-item>

                <template
                  v-if="
                    roleMama
                      && !isFixedInvoiceStatusDraftOrApproved
                      && actions.canApproveOrDisapprove(item.invoiceType, item.status)
                  "
                >
                  <!-- ANCHOR Table row action dropdown: Approve Invoice -->
                  <b-dropdown-item
                    @click="onHandleApproveMultipleInvoices([item])"
                  >
                    <feather-icon icon="PlusSquareIcon" />
                    <span class="align-middle ml-50">{{ $t('invoice.btn.approve') }}</span>
                  </b-dropdown-item>

                  <!-- ANCHOR Table row action dropdown: Disapprove Invoice -->
                  <b-dropdown-item
                    @click="onHandleApproveMultipleInvoices([item], false)"
                  >
                    <feather-icon icon="MinusSquareIcon" />
                    <span class="align-middle ml-50">{{ $t('invoice.btn.disapprove') }}</span>
                  </b-dropdown-item>
                </template>

                <template
                  v-if="
                    roleMama
                      && !isFixedInvoiceStatusAwaitingApproval
                      && actions.canSignOrRefuseSign(item.invoiceType, item.status)
                  "
                >
                  <!-- ANCHOR Table row action dropdown: Sign Invoice -->
                  <b-dropdown-item
                    @click="onHandleSignMultipleInvoices([item])"
                  >
                    <feather-icon icon="ZapIcon" />
                    <span class="align-middle ml-50">{{ $t('invoice.btn.sign') }}</span>
                  </b-dropdown-item>

                  <!-- ANCHOR Table row action dropdown: Refuse To Sign Invoice -->
                  <b-dropdown-item
                    @click="onHandleRefuseSignMultipleInvoices([item])"
                  >
                    <feather-icon icon="ZapOffIcon" />
                    <span class="align-middle ml-50">{{ $t('invoice.btn.refuseSign') }}</span>
                  </b-dropdown-item>
                </template>

                <!-- ANCHOR Table row action dropdown: View Detail Invoice -->
                <b-dropdown-item
                  v-if="actions.canViewDetail(item.invoiceType, item.status)"
                  :to="{ name: 'apps-invoicesOld-view', params: { id: item.id } }"
                >
                  <feather-icon icon="BookOpenIcon" />
                  <span class="align-middle ml-50">{{ $t('Detail') }}</span>
                </b-dropdown-item>

                <!-- ANCHOR Table row action dropdown: Update Invoice -->
                <b-dropdown-item
                  v-if="actions.canUpdate(item.invoiceType, item.status)"
                  :to="{ name: 'apps-invoicesOld-update', params: { id: item.id } }"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t('update') }}</span>
                </b-dropdown-item>

                <!-- ANCHOR Table row action dropdown: Adjust Invoice -->
                <b-dropdown-item
                  v-if="actions.canAdjust(item.invoiceType, item.status)"
                  :to="{ name: 'apps-invoicesOld-adjust', params: { id: item.id } }"
                >
                  <feather-icon icon="Edit3Icon" />
                  <span class="align-middle ml-50">{{ $t('adjust') }}</span>
                </b-dropdown-item>

                <!-- ANCHOR Table row action dropdown: Replace Invoice -->
                <b-dropdown-item
                  v-if="actions.canReplace(item.invoiceType, item.status)"
                  :to="{ name: 'apps-invoicesOld-replace', params: { id: item.id } }"
                >
                  <feather-icon icon="RepeatIcon" />
                  <span class="align-middle ml-50">{{ $t('replace') }}</span>
                </b-dropdown-item>

                <!-- Dropdown: Cancel Invoice -->
                <!-- NOTE: Agency can or can not cancel invoice depends on owner's request
                => maybe change in future  -->
                <b-dropdown-item
                  v-if="roleMama && actions.canCancel(item.invoiceType, item.status)"
                  @click="onShowPopupCancelInvoice(index, item)"
                >
                  <feather-icon icon="XSquareIcon" />
                  <span class="align-middle ml-50">{{ $t('invoice.btn.cancelInvoice') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </b-overlay>
      <!-- !SECTION -->

      <!-- SECTION Table Footer -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-center"
          >
            <span class="text-muted">
              {{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
              {{ dataMeta.of }} {{ $t('paginationText.items') }}
            </span>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="sizePerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <!-- !SECTION -->
    </b-card>

    <template v-if="enabledActions">
      <!-- Sign Invoice & Send Email -->
      <InvoicePopupSendEmail
        :id-popup-send-email="idPopupSendEmail"
        :use-when-sign-invoice="useWhenSignMultipleInvoices"
        :auto-send-email.sync="autoSendEmail"
        :invoices="dataSendEmailMultipleInvoices"
        @hidden="useWhenSignMultipleInvoices = false"
        @sendEmailSuccess="updateListViewAfterSendEmailMultipleInvoices()"
        @onSignInvoiceWithSendEmailOption="onSignMultipleInvoices"
        @showPreview="onPreviewById"
      />

      <InvoicePopupCancel
        :id-popup-cancel="idPopupCancel"
        :invoice-detail="invoiceDetailForCancel"
        @cancelInvoiceSuccess="updateListViewAfterCancelInvoice"
      />

      <InvoicePopupPreview
        :id-popup-preview="idPreviewInvoice"
        :preview-data="previewData"
      />
      <InvoicePopupPreviewIssuePdf
        :id-popup-preview-issue-pdf="idPreviewPdf"
        :pdf-url="pdfUrl"
      />
    </template>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BInputGroup,
  BFormInput,
  BFormCheckbox,
  BOverlay,
  BTable,
  BPagination,
  BBadge,
  BLink,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  watch,
  onUnmounted,
} from '@vue/composition-api'

import store from '@/store'
import {
  INVOICE_APP_STORE_MODULE_NAME as INVOICE_STORE,
  INVOICE_TABLE_COLUMNS,
  CHARACTERISTIC_VALUES as TYPES,
} from '@/constants/invoice'
import {
  sizePerPageOptions,
} from '@/constants/selectOptions'

import {
  dateTime,
  getDate,
} from '@core/utils/filter'

import {
  canSignOrRefuseSignMultipleInvoices,
  onHandleSignMultipleInvoices,
  onSignMultipleInvoices,

  onHandleRefuseSignMultipleInvoices,
  onRefuseSignMultipleInvoices,

  canSendToApproveMultipleInvoices,
  onHandleSendToApproveMultipleInvoices,
  onSendToApproveMultipleInvoices,

  canApproveOrDisapproveMultipleInvoices,
  onHandleApproveMultipleInvoices,
  onApproveMultipleInvoices,

  canSendEmailMultipleInvoices,
  onHandleSendEmailMultipleInvoices,
  updateListViewAfterSendEmailMultipleInvoices,
} from './useActionMultipleInvoices'
import InvoiceListFilters from './InvoiceListFilters.vue'
import useInvoiceList from './useInvoiceList'
import invoiceStoreModule from '../invoiceStoreModule'
import {
  getLocaleLabelOfInvoiceType,
  getLocaleLabelOfInvoiceStatus,
  getLocaleFullName,
  formatCurrency,
  actions,
  useShowToast,
} from '../useInvoice'
import {
  getSumOfTotalPayment,
  getTotalAmountBeforeTax,
  getTotalVat,
  getTotalCollectionFee,
} from '../useInvoicePayment'
import {
  getInvoiceDetailForSendEmail,
  getInvoiceDetailForSendEmailAfterSign,
  showPopupInvoiceSendEmail,
} from '../invoice-popup/useInvoiceSendEmail'
import {
  showPopupInvoiceCancel,
  getInvoiceDetailForCancel,
  updateListViewAfterCancelInvoice,
} from '../invoice-popup/useInvoiceCancel'
import {
  showPopupPreviewInvoiceOrPdf,
  getPreviewDataFromInvoiceDetail,
} from '../invoice-popup/useInvoicePopupPreview'

export default {
  components: {
    InvoiceListFilters,

    BCard,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BFormCheckbox,
    BFormInput,
    BOverlay,
    BTable,
    BPagination,
    BBadge,
    BLink,
    BTooltip,

    vSelect,

    InvoicePopupSendEmail: () => import('../invoice-popup/InvoicePopupSendEmail.vue'),
    InvoicePopupCancel: () => import('../invoice-popup/InvoicePopupCancel.vue'),
    InvoicePopupPreview: () => import('../invoice-popup/InvoicePopupPreview.vue'),
    InvoicePopupPreviewIssuePdf: () => import('../invoice-popup/InvoicePopupPreviewIssuePdf.vue'),
  },

  props: {
    enabledActions: {
      type: [String, Boolean],
      default: true,
    },
    fixedInvoiceTypes: {
      type: Array,
      default: undefined,
    },
    fixedInvoiceStatus: {
      type: Array,
      default: undefined,
    },
    updateSelectionCount: {
      type: [String, Boolean],
      default: false,
    },
    popupIdSufix: {
      type: String,
      default: 'invoice-list',
    },
    previousSelectedIds: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    tableColumns() {
      if (this.enabledActions) return INVOICE_TABLE_COLUMNS

      const tableColumnsNoActionsColumn = [...INVOICE_TABLE_COLUMNS]
      tableColumnsNoActionsColumn.pop()
      return tableColumnsNoActionsColumn
    },
    roleMama() {
      return this.$store.state.userStore.roleMama // true: role Mama, false: role Agency
    },
    isFixedInvoiceStatusAwaitingApproval() {
      return this.fixedInvoiceStatus
        && this.fixedInvoiceStatus.length === 1
        && this.fixedInvoiceStatus[0] === 'WAITING_TO_APPROVE' // trạng thái CHỜ DUYỆT
    },
    isFixedInvoiceStatusDraftOrApproved() {
      return this.fixedInvoiceStatus
        && this.fixedInvoiceStatus.length === 2
        && this.fixedInvoiceStatus.every(status => (
          status === 'DRAFT' // trạng thái NHÁP
          || status === 'APPROVED' // trạng thái CHỜ KÝ | trạng thái ĐÃ DUYỆT
        ))
    },
    idPopupSendEmail() {
      return `id-popup-send-email-in-${this.popupIdSufix}`
    },
    idPopupCancel() {
      return `id-popup-cancel-invoice-in-${this.popupIdSufix}`
    },
    idPreviewInvoice() {
      return `id-popup-preview-invoice-in-${this.popupIdSufix}`
    },
    idPreviewPdf() {
      return `id-popup-preview-pdf-in-${this.popupIdSufix}`
    },

    isAlreadySelectAll() {
      if (!this.previousSelectedIds.length) return false

      return this.idsList.every(id => this.previousSelectedIds.includes(id))
    },
    disabledSelectAll() {
      return this.isAlreadySelectAll || !this.maxSelectionCount
    },
    isSelectAll() {
      if (this.isAlreadySelectAll) return true
      if (!this.maxSelectionCount || this.maxSelectionCount !== this.selectionCount) return false
      return this.idsCanSelect.every(id => this.selectedIds.includes(id))
    },
    canSelectMore() {
      return this.maxSelectionCount && this.maxSelectionCount > this.selectionCount
    },
    showCheckboxSelectAll() {
      if (this.isSelectAll) return true
      if (this.notSelect && this.maxSelectionCount && this.maxSelectionCount === this.idsList.length) return true
      return !this.canSelectMore || (this.notSelect && !this.previousSelectedIds.length)
    },
  },

  setup(props, { emit }) {
    // Register module
    if (!store.hasModule(INVOICE_STORE)) store.registerModule(INVOICE_STORE, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE)) store.unregisterModule(INVOICE_STORE)
    })

    const showToast = useShowToast()

    function showToastError(message) {
      showToast(message, { success: false })
    }

    function showToastSuccess(message) {
      showToast(message, { success: true })
    }

    const onDebounceSearch = debounce((search, callBack) => {
      const trimSearch = search.trim()
      callBack(trimSearch)
    }, 500)

    function onRefetchData(trimSearch) {
      this.searchKeywords = trimSearch
      this.$refs.invoiceListFilters.onUpdateFilters()
    }

    function confirmExport() {
      // show modal confirm export employee
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmExport') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
          noCloseOnBackdrop: true,
        })
        .then(value => {
          if (value) {
            this.exportInvoice()
          }
        })
    }

    const {
      refInvoiceListTable,
      loading,
      searchKeywords,
      totalRows,
      sizePerPage,
      currentPage,
      sortBy,
      isSortDirDesc,
      dataMeta,

      invoicesList,
      idsList,
      selectedIds,
      idsCanSelect,
      itemsCanSelect,
      maxSelectionCount,
      selectedInvoices,
      // NOTE: selectedValidInvoices is used for a action that can be performed with multiple invoices.
      selectedValidInvoices,
      selectionCount,
      notSelect,

      onHandleSelectAllOrSelectNone,
      fetchInvoices,
      refetchData,
      clearFilter,
      isChecked,
      toggleSelectItem,
      exportInvoice,
    } = useInvoiceList(props.fixedInvoiceTypes, props.fixedInvoiceStatus)

    // SECTION cancel invoice
    const invoiceDetailForCancel = ref(null)

    function onShowPopupCancelInvoice(index, invoiceData) {
      this.invoiceDetailForCancel = getInvoiceDetailForCancel(invoiceData)
      this.invoiceDetailForCancel.index = index
      this.showPopupInvoiceCancel(this.idPopupCancel)
    }
    // !SECTION cancel invoice

    // SECTION Sign & Send Email for Multiple Invoices
    const dataSendEmailMultipleInvoices = ref([])
    const useWhenSignMultipleInvoices = ref(false)
    const autoSendEmail = ref(false)

    function setDataSendEmailMultipleInvoices(invoices) {
      dataSendEmailMultipleInvoices.value = getInvoiceDetailForSendEmail(invoices)
    }
    // !SECTION Sign & Send Email for Multiple Invoices

    function setDataSendEmailMultipleInvoicesAfterSign(invoices) {
      dataSendEmailMultipleInvoices.value = getInvoiceDetailForSendEmailAfterSign(invoices)
    }

    // for src/views/apps/invoices/invoice-popup/InvoicePopupList.vue
    if (props.updateSelectionCount) {
      watch(
        () => selectionCount.value,
        val => emit('updateSelectionCount', val),
      )
    }

    // preview invoice
    const pdfUrl = ref(null)
    const previewData = ref(null)

    function onPreviewByItem(item) {
      this.pdfUrl = item.pdfUrl
      this.previewData = getPreviewDataFromInvoiceDetail(item)

      this.showPopupPreviewInvoiceOrPdf(
        this.pdfUrl,
        this.idPreviewInvoice,
        this.idPreviewPdf,
      )
    }

    function onPreviewById(id) {
      const item = this.selectedValidInvoices.find(el => el.id === id)
      if (item) this.onPreviewByItem(item)
    }

    function isAlreadySelected(item) {
      return props.previousSelectedIds.includes(item.id)
    }

    watch(
      idsList,
      () => {
        if (!props.previousSelectedIds.length) {
          idsCanSelect.value = [...idsList.value]
          itemsCanSelect.value = [...invoicesList.value]
        } else {
          const resultIds = []
          const resultItems = []
          idsList.value.forEach(id => {
            const index = props.previousSelectedIds
              ? props.previousSelectedIds.findIndex(previousSelectedId => previousSelectedId === id)
              : -1
            if (index < 0) {
              resultIds.push(id)
              resultItems.push(invoicesList.value.find(item => item.id === id))
            }
          })

          idsCanSelect.value = resultIds
          itemsCanSelect.value = resultItems
        }
      },
      {
        immediate: true,
        deep: true,
      },
    )

    return {
      // useShowToast
      showToastError,
      showToastSuccess,

      // from imports
      TYPES,
      sizePerPageOptions,
      actions,

      dateTime,
      getDate,
      formatCurrency,
      getLocaleLabelOfInvoiceType,
      getLocaleLabelOfInvoiceStatus,
      getLocaleFullName,

      getSumOfTotalPayment,
      getTotalAmountBeforeTax,
      getTotalVat,
      getTotalCollectionFee,

      // returned by useInvoiceList()
      refInvoiceListTable,
      loading,
      searchKeywords,
      totalRows,
      sizePerPage,
      currentPage,
      sortBy,
      isSortDirDesc,
      dataMeta,

      invoicesList,
      idsList,
      selectedIds,
      idsCanSelect,
      itemsCanSelect,
      maxSelectionCount,
      selectedInvoices,
      // NOTE: selectedValidInvoices is used for a action that can be performed with multiple invoices.
      selectedValidInvoices,
      selectionCount,
      notSelect,

      onHandleSelectAllOrSelectNone,
      isAlreadySelected,
      fetchInvoices,
      refetchData,
      clearFilter,
      isChecked,
      toggleSelectItem,

      onDebounceSearch,
      onRefetchData,

      // Sign & Send Email for Multiple Invoices
      dataSendEmailMultipleInvoices,
      useWhenSignMultipleInvoices,
      autoSendEmail,
      showPopupInvoiceSendEmail,

      // Sign Multiple Invoices
      canSignOrRefuseSignMultipleInvoices,
      setDataSendEmailMultipleInvoicesAfterSign,
      onHandleSignMultipleInvoices,
      onSignMultipleInvoices,

      // Refuse Sign Multiple Invoices
      onHandleRefuseSignMultipleInvoices,
      onRefuseSignMultipleInvoices,

      // Send To Approve Multiple Invoices
      canSendToApproveMultipleInvoices,
      onHandleSendToApproveMultipleInvoices,
      onSendToApproveMultipleInvoices,

      // Approve Multiple Invoices or Disapprove  Multiple Invoices
      canApproveOrDisapproveMultipleInvoices,
      onHandleApproveMultipleInvoices,
      onApproveMultipleInvoices,

      // Send Email Multiple Invoices
      canSendEmailMultipleInvoices,
      setDataSendEmailMultipleInvoices,
      onHandleSendEmailMultipleInvoices,
      updateListViewAfterSendEmailMultipleInvoices,

      // cancel invoice
      invoiceDetailForCancel,
      onShowPopupCancelInvoice,
      showPopupInvoiceCancel,
      updateListViewAfterCancelInvoice,

      // preview invoice
      pdfUrl,
      previewData,
      onPreviewByItem,
      onPreviewById,
      showPopupPreviewInvoiceOrPdf,

      confirmExport,
      exportInvoice,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.hover-text-primary:hover {
  color: #3DA5DE;
}
.vs--disabled .vs__selected {
  color: inherit;
}
</style>
