import isEmpty from 'lodash/isEmpty'
import { useToast } from 'vue-toastification/composition'

import {
  INVOICE_TYPE_VALUES as TYPES,
  INVOICE_TYPE_OPTIONS,
  INVOICE_STATUS_VALUES as STATUS,
  INVOICE_STATUS_OPTIONS,
  PAYMENT_METHOD_OPTIONS,
  TICKET_TYPE_OPTIONS_EXTEND_MIX,
  CHARACTERISTIC_VALUES,
  CHARACTERISTIC_OPTIONS,
} from '@/constants/invoice'

import { getDate, formatVnCurrency } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export function getLocaleLabelOfInvoiceType(invoiceType) {
  return this.$t(
    INVOICE_TYPE_OPTIONS.find(item => item.value === invoiceType).label,
  )
}

export function getLocaleLabelOfInvoiceStatus(invoiceStatus) {
  return this.$t(
    INVOICE_STATUS_OPTIONS.find(item => item.value === invoiceStatus)?.label,
  )
}

export function getLocaleFullName(obj) {
  if (isEmpty(obj)) return ''
  const locale = localStorage.getItem('Locale') || 'vi'
  const pattern = /(MISS|MSTR)$/
  const firstName = (obj?.firstName.replace(pattern, '')) || ''
  const arr = locale === 'vi'
    ? [obj?.lastName, firstName]
    : [firstName, obj?.lastName]
  return arr.filter(name => !isEmpty(name)).join(' ')
}

export function getLocaleLabelOfPaymentMethod(paymentMethod) {
  return this.$t(
    PAYMENT_METHOD_OPTIONS.find(item => item.value === paymentMethod).label,
  )
}

export function getLocaleTicketType(ticketType) {
  if (!ticketType) return ''
  return this.$t(
    TICKET_TYPE_OPTIONS_EXTEND_MIX.find(item => item.value === ticketType)
      .label,
  )
}

export function getLocaleCharacteristic(characteristic) {
  const value = Object.values(CHARACTERISTIC_VALUES).includes(characteristic)
    ? characteristic
    : CHARACTERISTIC_VALUES.goods
  return this.$t(
    CHARACTERISTIC_OPTIONS.find(item => item.value === value).label,
  )
}

export function formatCurrency(val) {
  if (!val || !Number(val)) return ''
  return formatVnCurrency(val)
}

/* Conditions to enable invoice actions */
const canViewDetail = (type, status) => {
  if ([TYPES.cancellation, TYPES.adjusted].includes(type)) return status === STATUS.released
  return true
}

const canPreview = (type, status) => {
  if ([TYPES.cancellation, TYPES.adjusted].includes(type)) return status === STATUS.released
  return true
}

const canUpdate = (type, status) => {
  if ([TYPES.cancellation, TYPES.adjusted].includes(type)) return false
  return [STATUS.draft, STATUS.refuseApproval, STATUS.refuseSignature].includes(
    status,
  )
}

const canAdjust = (type, status) => status === STATUS.released
  && [TYPES.original, TYPES.adjustment].includes(type)

const canReplace = (type, status) => status === STATUS.released
  && [TYPES.original, TYPES.replacement, TYPES.cancellation].includes(type)

const canCancel = (type, status) => status === STATUS.released
  && [TYPES.original, TYPES.adjustment, TYPES.replacement].includes(type)

const canSendEmail = (type, status) => {
  if ([TYPES.cancellation, TYPES.adjusted].includes(type)) return status === STATUS.released
  return true
}

const canSignOrRefuseSign = (type, status) => {
  if ([TYPES.cancellation, TYPES.adjusted].includes(type)) return false
  return status === STATUS.draft || status === STATUS.approved
}

const canSendToApprove = (type, status) => {
  if ([TYPES.cancellation, TYPES.adjusted].includes(type)) return false
  return status === STATUS.draft || status === STATUS.refuseApproval
}

const canApproveOrDisapprove = (type, status) => {
  if ([TYPES.cancellation, TYPES.adjusted].includes(type)) return false
  return status === STATUS.awaitingApproval
}

const hasActions = (type, status) => {
  if ([TYPES.cancellation, TYPES.adjusted].includes(type)) return status === STATUS.released
  return true
}

export const actions = {
  canViewDetail, // có thể XEM CHI TIẾT
  canPreview, // có thể XEM TRƯỚC BẢN PDF
  canUpdate, // có thể CẬP NHẬT
  canAdjust, // có thể ĐIỀU CHỈNH
  canReplace, // có thể THAY THẾ
  canCancel, // có thể HUỶ BỎ
  canSendEmail, // có thể GỬI EMAIL
  canSignOrRefuseSign, // có thể KÝ hoặc có thể TỪ CHỐI KÝ
  canSendToApprove, // có thể GỬI DUYỆT
  canApproveOrDisapprove, // có thể DUYỆT hoặc TỪ CHỐI DUYỆT
  hasActions, // có ít nhất một trong các actions trên đây
}
/* !Conditions to enable invoice actions */

/* common useShowToast */
// NOTE: because using of useToast from 'vue-toastification/composition',
// - make sure to run useShowToast() in script setup or setup()
export const useShowToast = () => {
  const toast = useToast()

  // @default behaviour: show error message
  // @params { success: true }: show success message
  const showToast = (
    message,
    option = {
      success: false,
    },
  ) => {
    toast({
      component: ToastificationContent,
      props: {
        title: message,
        icon: option.success ? 'CoffeeIcon' : 'AlertTriangleIcon',
        variant: option.success ? 'success' : 'danger',
      },
    })
  }

  return showToast
}
/* !common useShowToast */

export function resolveFightTypeFromTicketType(ticketType) {
  if (ticketType === 'DOMESTIC') return 'Domestic'
  if (ticketType === 'INTERNATIONAL') return 'Inbound'
  return ''
}

export function getTopRowData(invoiceData, isParent = false) {
  const parentInvoice = isParent ? invoiceData : invoiceData.parentInvoice
  return {
    invoiceNumber: parentInvoice.invoiceNumber,
    form: parentInvoice.form,
    serial: parentInvoice.serial,
    createdDate: getDate(parentInvoice.createdAt),
  }
}

export function getConfirmationFromUser(msgConfirm, callBack) {
  this.$bvModal
    .msgBoxConfirm(msgConfirm, {
      title: this.$t('modal.confirm'),
      size: 'sm',
      okVariant: 'info',
      okTitle: this.$t('modal.yes'),
      cancelTitle: this.$t('modal.no'),
      cancelVariant: 'outline-danger',
      hideHeaderClose: true,
      centered: true,
      noCloseOnBackdrop: true,
    })
    .then(value => {
      if (value) {
        callBack()
      }
    })
}
